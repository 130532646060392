import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Editor & Terminal`}</h3>
    <ul>
      <li parentName="ul">
        <a href="https://code.visualstudio.com/">Visual Studio Code</a>
      </li>
      <li parentName="ul">
        <a href="https://www.jetbrains.com/phpstorm/">PHPStorm</a>
      </li>
    </ul>
    <h3>{`Operating system`}</h3>
    <ul>
      <li parentName="ul">{`Linux`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      